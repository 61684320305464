<template>
  <v-card
    elevation="8"
    class="card_home rounded-xl d-flex flex-column"
    @click="goToAgencies"
    color="white"
  >
    <v-card-title>
      Agencies
    </v-card-title>
    <v-card-text>
      In this section you can check the exact number of Agencies.
    </v-card-text>
    <v-card-title class="flex-grow py-0">
      <highcharts
        :options="chartOptions"
        class="homechart_container"
      ></highcharts>
    </v-card-title>
    <v-card-text class="d-flex mb-3">
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">{{getAgencyCount.registeredAgencies}}</div>
        <div class="gray-400 mt-1">Registered</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">{{getAgencyCount.pendingAgencies}}</div>
        <div class="gray-400 mt-1 text-center">Waiting approved</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="gray-500 font-24 app-bold-font">{{getAgencyCount.availableAgencies}}</div>
        <div class="gray-400 mt-1">Approved</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="error--text font-24 app-bold-font">{{getAgencyCount.rejectedAgencies}}</div>
        <div class="gray-400 mt-1">Rejected</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
  return {
    chartOptions: {
      chart: {
        type: "line",
        borderRadius: 20,
        marginBottom: 50,
        marginLeft: 50,
        marginRight: 20,
        marginTop: 50
      },
      credits: {
        enabled: false
      },
      title: {
        text: "Agency Growth Over Time"
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter: function () {
            return moment(this.value).format("MMM DD");
          }
        }
      },
      yAxis: {
        title: { text: "Number of Agencies" }
      },
      series: [
        {
          name: "Agencies",
          data: this.agencyGraphDatas,
          color: "#7024C4",
          marker: {
            enabled: true
          }
        }
      ],
      tooltip: {
        formatter: function () {
          return moment(this.x).format("MMM DD") + " : " + this.y;
        }
      }
    }
  };
},
  computed: {
    ...mapGetters("adminDashboard", ["getAgencyCount", "agencyGraphData"]),
    agencyGraphDatas() {
      return this.agencyGraphData || [];
    }
  },
  methods: {
    ...mapActions("adminDashboard", ["fetchAgencyCountDetails","fetchAgencyGraphDetails"]),
    goToAgencies() {
      this.$router.push({ name: "admin-agencies" });
    }
  },
  watch: {
    blogs(newValue) {
      this.menu[2].count = newValue;
    },
    reported(newValue) {
      this.menu[1].count = newValue;
    },
      agencyGraphData(newData) {
    this.chartOptions.series[0].data = newData;
  }
      },
  mounted() {
    this.fetchAgencyCountDetails();
    this.fetchAgencyGraphDetails();
  }
};
</script>
<style scoped>
.card_home {
  height: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.highcharts-container {
  height: 100% !important;
}
.highcharts-root {
  height: 100%;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.homechart_container {
  min-height: 150px;
  height: 100%;
  width: 100%;
}
</style>
