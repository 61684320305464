<template>
  <v-card
    elevation="8"
    class="card_home rounded-xl d-flex flex-column"
    @click="goToEvents"
    color="white"
  >
    <v-card-title>
      Events
    </v-card-title>
    <v-card-text>
      In this section you can check the exact number of Events.
    </v-card-text>
    <v-card-title class="flex-grow">
      <highcharts
        :options="chartOptions"
        class="homechart_container"
      ></highcharts>
    </v-card-title>
    <v-card-text class="d-flex mb-3">
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">{{getEventCount.totalEvents}}</div>
        <div class="gray-400 mt-1">Total Events</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">{{getEventCount.totalIndividualEvents}}</div>
        <div class="gray-400 mt-1">Individual Posts</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="gray-500 font-24 app-bold-font">{{getEventCount.totalCompetitionEvents}}</div>
        <div class="gray-400 mt-1">Competition Posts</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      selected: "Volunteer Hours",
      menu: [
        {
          title: "Send message",
          reply: "Now",
          route: "admin-message",
          count: 0
        },
        {
          title: "Reported",
          reply: "Check more",
          route: "reported",
          count: 0
        },
        {
          title: "Blogs",
          reply: "Manage",
          route: "blog-feeds",
          count: 0
        }
      ],
      chartOptions: {
        showInLegend: false,
        chart: {
          backgroundColor: "white",
          renderTo: "container",
          borderRadius: 20,
          marginBottom: 50,
          marginLeft: 50,
          marginRight: 20,
          marginTop: 50,
          type: "areaspline"
        },
        credits: {
          enabled: false
        },
        title: {
          text: " " //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        // plotOptions: {
        //   column: {
        //     depth: 25
        //   },
        //   series: {
        //     color: "#7024C4"
        //   }
        // },
        xAxis: {
          type: "datetime",
          labels: {
            formatter: function () {
              return moment(this.value).format("MMM DD");
            }
          }
        },
        yAxis: {
          title: { text: "" }
        },
        series: [
          {
            showInLegend: false,
            data: this.eventGraphDatas,
            color: "#7024C4",
            marker: {
              enabled: true
            }
          }
        ],
      tooltip: {
        formatter: function () {
          return moment(this.x).format("MMM DD") + " : " + this.y;
        }
      }
      }
    };
  },
  computed: {
    ...mapGetters("adminDashboard", ["getEventCount", "eventGraphData"]),
    eventGraphDatas() {
      return this.eventGraphData || [];
    }
  },
  methods: {
    ...mapActions("adminDashboard", ["fetchEventCountDetails","fetchEventGraphDetails"]),
    goToEvents() {
      this.$router.push({ name: "events" });
    }
  },
    watch: {
      eventGraphData(newData) {
          this.chartOptions.series[0].data = newData;
      }
      },
  mounted() {
    this.fetchEventGraphDetails();
    this.fetchEventCountDetails();
  }
};
</script>
<style scoped>
.card_home {
  height: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.highcharts-container {
  height: 100% !important;
}
.highcharts-root {
  height: 100%;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.homechart_container {
  min-height: 250px;
  height: 100%;
  width: 100%;
}
</style>
