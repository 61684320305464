<template>
  <v-card
    elevation="8"
    class="card_home rounded-xl d-flex flex-column"
    @click="goToVolunteers"
    color="white"
  >
    <v-card-title>
      Volunteers
    </v-card-title>
    <v-card-text>
      In this section you can check the exact number of Volunteers.
    </v-card-text>
    <v-card-title class="flex-grow pa-0">
      <highcharts
        :options="chartOptions"
        class="homechart_container"
      ></highcharts>
    </v-card-title>
    <v-card-text class="d-flex mb-3">
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">25132</div>
        <div class="gray-400 mt-1">Total</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">1541</div>
        <div class="gray-400 mt-1">Working</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="gray-500 font-24 app-bold-font">53261</div>
        <div class="gray-400 mt-1">Hours</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      selected: "Volunteer Hours",
      menu: [
        {
          title: "Send message",
          reply: "Now",
          route: "admin-message",
          count: 0
        },
        {
          title: "Reported",
          reply: "Check more",
          route: "reported",
          count: 0
        },
        {
          title: "Blogs",
          reply: "Manage",
          route: "blog-feeds",
          count: 0
        }
      ],
      chartOptions: {
        showInLegend: false,
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          backgroundColor: "white",
          renderTo: "container"
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: "{point.percentage:.1f}%"
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %"
            }
          }
        },
        series: [
          {
            colorByPoint: true,
            name: "Age group",
            data: this.volunteersGraphDatas
          }
        ]
      }
    };
  },
    computed: {
    ...mapGetters("adminDashboard", ["volunteersGraphData"]),
    volunteersGraphDatas() {
      return this.volunteersGraphData || [];
    }
  },
  methods: {
    ...mapActions("adminDashboard", ["fetchVolunteersGraphDetails"]),
    goToVolunteers() {
      this.$router.push({ name: "admin-volunteers" });
    }
  },
    watch: {
      volunteersGraphData(newData) {
    this.chartOptions.series[0].data = newData;
  }
      },
  mounted() {
    this.fetchVolunteersGraphDetails();
  }
};
</script>
<style scoped>
.card_home {
  height: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.highcharts-container {
  height: 100% !important;
}
.highcharts-root {
  height: 100%;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.homechart_container {
  min-height: 250px;
  height: 100%;
  width: 100%;
}
</style>
