import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    awards: [],
    reedemedAwards:[],
    users:[]
  },
  getters: {
    getAllAwards(state) {
      return state.awards;
    },
    getReedemedUsers(state) {
      return state.users;
    }
  },
  mutations: {
    setAwards(state, data) {
      state.awards = data;
    },
    setUsers(state,data){
      state.users = data;
      
    },
    setReedemedAwards(state, data) {
        state.reedemedAwards = data;
    },
    addAward(state, data) {
      const item = state.awards.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.awards.unshift(data);
      }
    },
    editAward(state, data) {
      const item = state.awards.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.awards.push(data);
      }
    },
    deleteAward(state, data) {
      state.awards = state.awards.filter(q => q._id !== data._id);
    },
    couponRedeem(state, data) {
      const item = state.awards.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.awards.unshift(data);
      }
    },
    UPDATE_USER_STATUS(state, { _id, used }) {
      if (!Array.isArray(state.users)) return; 
      const user = state.users.redeemedUsers.find(user => user._id === _id);
      if (user) {
        user.used = used;
      }
    }
  },
  actions: {
    fetchAllAwards({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("coupon/getAllCoupons", params)
          .then(res => {
            if (res.data.success == true) {
              commit("setAwards", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }, 
    fetchRedeemedAwards({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("coupon/redeemHistory")
          .then(res => {
            if (res.data.success == true) {
              commit("setReedemedAwards", res.data.coupons);
              resolve(res.data.coupons);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAward({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/get", params)
          .then(res => {
            if (res.data.success == true) {
              commit("setAward", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addAward({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("coupon/createCoupon", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("addAward", res.data.coupon);
              resolve(res.data.coupon);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editAward({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("coupon/editCoupon", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("editAward", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteAward({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("coupon/deleteCoupon", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("deleteAward", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    reedemCoupon({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("coupon/redeemCoupon", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("couponRedeem", res.data);
              resolve(res.data);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getEligibles({ rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("coupon/getEligibleCoupons", {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.coupons);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getVolunteerHours({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("awards/volunteer-hours", param, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getCopuonCounts({ rootState },param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("coupon/getCompanyCoupons",param, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
        });
  },
  getCouponDetails({ rootState,commit },param) {
    return new Promise((resolve, reject) => {
      axiosInstance(rootState.auth.token)
        .post("coupon/getCouponDetails",param, {
          Authorization: rootState.auth.token
        })
        .then(res => {
          if (res.data) {
            commit("setUsers", res.data);
            resolve(res.data);
          } else {
            reject(res.data.message);
          }
        })
        .catch(error => {
          reject(error);
        });
      });
},
updateCouponCompleted({ rootState, commit }, param) {
  return new Promise((resolve, reject) => {
    axiosInstance(rootState.auth.token)
      .post("coupon/couponUsed", param, {
        headers: { Authorization: rootState.auth.token }  
      })
      .then(res => {
        if (res.data.success) {
          commit("UPDATE_USER_STATUS", param);
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
}
}
