import axiosInstance from "@/api/axios";
export default {
  namespaced: true,
  state: {
    status: null,
    conversations: [],
    unreadCount: {},
    unreadmessageCount: 0,
    currentRoute: null,
    loading: false,
    initializing: false,
    chat: [],
    singleRoom: {}
  },
  getters: {
    getStatus(state) {
      return state.status;
    },
    getConversations(state) {
      return state.conversations;
    },
    getUnreadMessagesCount(state) {
      return state.unreadmessageCount;
    },
    getChats(state) {
      return state.chat;
    },
    getSingleRoom(state) {
      return state.singleRoom;
    }
  },
  mutations: {
    setStatus(state, data) {
      state.status = data;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setInitializing(state, data) {
      state.initializing = data;
    },
    setConversations(state, data) {
      var array = data;
      array.sort((a, b) => {
        var aTime = new Date(a.date);
        var bTime = new Date(b.date);
        if (aTime.getTime() > bTime.getTime()) return -1;
        else if (aTime.getTime() < bTime.getTime()) return 1;
        return 0;
      });
      state.conversations = array;
    },
    setConversation(state, data) {
      state.conversations = state.conversations.map(c =>
        c.uniqueName == data.uniqueName ? data : c
      );
    },
    setUnreadMessageCount(state, param) {
      state.unreadCount[param.key] = param.value;
      state.unreadCount = { ...state.unreadCount };
    },
    setSingleRoom(state, data) {
      console.log("single room from store", data);
      state.singleRoom = data;
    },
    SET_UNREAD_MESSAGES_COUNT(state, data) {
      console.log("unread message count", data);
      console.log("unread message count", state);

      state.unreadmessageCount = data;
    },
    setRoute(state, route) {
      state.currentRoute = route;
    },
    SET_CHAT(state, chat) {
      state.chat = chat;
    }
  },
  actions: {
    getAllConversations({ state, commit, rootState }) {
      if (state.loading) return;
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/all")
          .then(res => {
            commit("setLoading", false);
            if (res.data.success == true) {
              commit("setConversations", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateConversation({ commit, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/update-conversation", param)
          .then(res => {
            if (res.data.success == true) {
              commit("setConversation", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addChat({ rootState }, id) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/add", { id })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteChat({ dispatch, rootState }, id) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/delete", { id })
          .then(res => {
            if (res.data.success == true) {
              dispatch("getAllConversations");
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchUsers({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-users", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchUsersEmail({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-users-email", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchVolunteersEmail({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-volunteers-email", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchUsersPhone({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-users-phone", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    searchVolunteersPhone({ rootState }, key) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/search-volunteers-phone", { key })
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendMessageNotification({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/notification", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAIChatMessage({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chat/ai", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    joinRoom({ rootState, state, commit }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chatNew/findRoomOf2Users", params)
          .then(res => {
            console.log(res.data, "findRoomOf2Users222");
            // check if chat is not empty, if empty add this as the first item, if not empty, add this as first item of chat
            if (state.chat && state.chat.length > 0) {
              let roomIndex = state.chat.findIndex(
                room => room._id == res.data.chat._id
              );
              if (roomIndex != -1) {
                let room = state.chat.splice(roomIndex, 1);
                state.chat.unshift(room[0]);
              } else {
                state.chat.unshift(res.data.chat);
              }
            } else {
              state.chat = [res.data.chat];
            }

            // set single room
            commit("setSingleRoom", res.data.chat);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getUnreadMessageCount({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chatNew/unreadMessagesCount")
          .then(res => {
            if (res.data.success == true) {
              commit("SET_UNREAD_MESSAGES_COUNT", res.data.unreadCount);
              resolve(res.data.unreadCount);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAllChats({ rootState, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chatNew/getRooms")
          .then(res => {
            console.log(res.data.chat, "getRooms");
            if (res.data.success == true) {
              commit("SET_CHAT", res.data.chat);
              resolve(res.data.chat);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addNewMessage({ rootState, state }, params) {
      if (state.chat && state.chat.length > 0) {
        let roomIndex = state.chat.findIndex(room => room._id == params.roomID);
        if (roomIndex != -1) {
          let room = state.chat.splice(roomIndex, 1);
          state.chat.unshift(room[0]);
        }
      }
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chatNew/addNewMessage", {
            roomID: params.roomID,
            message: {
              fromUser: params.fromUser,
              toUser: params.toUser,
              message: params.message,
              fromUserName: params.fromUserName,
              date: new Date()
            }
          })
          .then(res => {
            console.log(res.data, "addNewMessage");
            if (res.data.status == true) {
              // add the messageID to the message object that is already added as the first item
              // add messageID to the message of the room with the aboveroomindex
              let roomIndex = state.chat.findIndex(
                room => room._id == params.roomID
              );
              if (roomIndex != -1) {
                // find the message in the messages array of the room with the params.message and with no _id
                let messageIndex = state.chat[roomIndex].messages.findIndex(
                  message => message.message == params.message && !message._id
                );
                if (messageIndex != -1) {
                  state.chat[roomIndex].messages[messageIndex]._id =
                    res.data.messageID;
                }
              }

              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    markMessageAsRead({ commit, rootState, state }, params) {
      console.log(params, "markMessageAsRead");
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("chatNew/markRead", {
            roomID: params.roomID,
            messageID: params.messageID,
            toUserID: params.toUserID
          })
          .then(res => {
            console.log(res.data, "markMessageAsRead");
            if (res.data.status == true) {
              if (state.unreadmessageCount > 0) {
                commit(
                  "SET_UNREAD_MESSAGES_COUNT",
                  state.unreadmessageCount - 1
                );
              }
              if (state.singleRoom._id == params.roomID) {
                let messageIndex = state.singleRoom.messages.findIndex(
                  message => message._id == params.messageID
                );
                state.singleRoom.messages[messageIndex].read = true;
              }
              if (state.chat && state.chat.length > 0) {
                let roomIndex = state.chat.findIndex(
                  room => room._id == params.roomID
                );
                if (roomIndex != -1) {
                  state.chat[roomIndex].unreadCount -= 1;
                }
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addMessageFromFcm({ commit, state }, params) {
      if (state.singleRoom._id == params.roomID) {
        let existingMessageIndex = state.singleRoom.messages.findIndex(
          message => message._id == params._id
        );
        if (existingMessageIndex === -1) {
          state.singleRoom.messages.push(params);
        }
      }
      if (state.chat && state.chat.length > 0) {
        let roomIndex = state.chat.findIndex(room => room._id == params.roomID);
        if (roomIndex != -1) {
          let existingMessageIndex = state.chat[roomIndex].messages.findIndex(
            message => message._id == params._id
          );
          if (existingMessageIndex === -1) {
            state.chat[roomIndex].messages.push(params);
          }
          if (state.singleRoom._id != params.roomID) {
            state.chat[roomIndex].unreadCount += 1;
          }
          let room = state.chat.splice(roomIndex, 1);
          state.chat.unshift(room[0]);
        }
      }
      commit("SET_UNREAD_MESSAGES_COUNT", state.unreadmessageCount + 1);
    },
    updateChatwithMessageReadStatus({ state }, params) {
      // update the chat array with the message read status
      if (state.chat && state.chat.length > 0) {
        let roomIndex = state.chat.findIndex(room => room._id == params.roomID);
        if (roomIndex != -1) {
          let messageIndex = state.chat[roomIndex].messages.findIndex(
            message => message._id == params._id
          );
          if (messageIndex != -1) {
            state.chat[roomIndex].messages[messageIndex].read = true;
          }
        }
      }
    }
  }
};
