<template>
  <v-card
    elevation="8"
    class="card_home rounded-xl d-flex flex-column"
    @click="goToNeeds"
    color="white"
  >
    <v-card-title class="pb-0">
      Needs
    </v-card-title>
    <v-card-title class="d-flex pt-0">
      <div
        style="width: 200px; padding: 20px; display: flex; align-items: center; flex-direction: column;"
      >
        <div class="primary-color font-24 app-bold-font">{{getNeedsCount.totalNeeds}}</div>
        <div class="font-14 gray-400">Total Needs</div>
        <div class="primary-color font-24 app-bold-font mt-2">{{getNeedsCount.availableNeeds}}</div>
        <div class="font-14 gray-400">Available</div>
        <div class="gray-500 font-24 app-bold-font mt-2">{{getNeedsCount.completedNeeds}}</div>
        <div class="font-14 gray-400">Completed</div>
      </div>
      <div class="flex-grow">
        <highcharts
          :options="chartOptions"
          class="homechart_container"
        ></highcharts>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      selected: "Volunteer Hours",
      menu: [
        {
          title: "Send message",
          reply: "Now",
          route: "admin-message",
          count: 0
        },
        {
          title: "Reported",
          reply: "Check more",
          route: "reported",
          count: 0
        },
        {
          title: "Blogs",
          reply: "Manage",
          route: "blog-feeds",
          count: 0
        }
      ],
      chartOptions: {
        showInLegend: false,
        chart: {
          backgroundColor: "white",
          renderTo: "container",
          borderRadius: 20,
          marginBottom: 50,
          marginLeft: 50,
          marginRight: 20,
          marginTop: 50,
          type: "column"
        },
        credits: {
          enabled: false
        },
        title: {
          text: " " //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        // plotOptions: {
        //   column: {
        //     depth: 25
        //   },
        //   series: {
        //     color: "#7024C4"
        //   }
        // },
      xAxis: {
        type: "datetime",
        labels: {
          formatter: function () {
            return moment(this.value).format("MMM DD");
          }
        }
      },
      yAxis: {
        title: { text: "Number of Needs" }
      },
        series: [
          {
            showInLegend: false,
           data: this.needGraphDatas,
              color: "#7024C4",
          marker: {
            enabled: true
          }
          }
        ],
      tooltip: {
        formatter: function () {
          return moment(this.x).format("MMM DD") + " : " + this.y;
        }
      }
      }
    };
  },
  computed: {
    ...mapGetters("adminDashboard", ["getNeedsCount", "needGraphData"]),
    needGraphDatas() {
      return this.needGraphData || [];
    }
  },
  methods: {
    ...mapActions("adminDashboard", ["fetchNeedCountDetails","fetchNeedGraphDetails"]),
    goToNeeds() {
      this.$router.push({ name: "agency-need-feeds" });
    }
  },
    watch: {
      needGraphData(newData) {
        this.chartOptions.series[0].data = newData;
      }
    },
  mounted() {
    this.fetchNeedGraphDetails();
    this.fetchNeedCountDetails();
  }
};
</script>
<style scoped>
.card_home {
  height: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.highcharts-container {
  height: 100% !important;
}
.highcharts-root {
  height: 100%;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.homechart_container {
  min-height: 300px;
  height: 100%;
  width: 100%;
}
</style>
