import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import HighchartsVue from "highcharts-vue";
import VueDragScroll from "vue-dragscroll";
import * as VueGoogleMaps from "vue2-google-maps";
import VueObserveVisibility from "vue-observe-visibility";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import VEmojiPicker from "v-emoji-picker";
import linkify from "vue-linkify";
import Vue2Editor from "vue2-editor";
import VueChatScroll from "vue-chat-scroll";
import Notification from "vue-notification";
import VueNativeNotification from "vue-native-notification";
import Meta from "vue-meta";
import VueHead from "vue-head";
import VueSocialSharing from "vue-social-sharing";
import firebaseApp from "./firebaseconfig"; // Import Firebase configuration

Vue.config.productionTip = false;
Vue.use(HighchartsVue);
Vue.use(VueNativeNotification, {
  requestOnNotify: true
});
Vue.use(VueDragScroll);
Vue.use(VueObserveVisibility);
Vue.use(VuePlyr);
Vue.use(VEmojiPicker);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAxnwgHYbXKBSCSFe_sFWfIY8F-LPcSJgQ",
    libraries: "places"
  },
  autobindAllEvents: true,
  installComponents: true
});
Vue.use(Meta);
Vue.use(Vue2Editor);
Vue.use(VueChatScroll);
Vue.directive("linkified", linkify);
Vue.use(Notification);
Vue.use(VueHead);
Vue.use(VueSocialSharing);

if ("serviceWorker" in navigator) {
  // Unregister existing service workers
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => {
      registration.unregister().then(() => {
        console.log("Unregistered old service worker.");
      });
    });
  });

  // Clear old caches
  // if ("caches" in window) {
  //   caches.keys().then(cacheNames => {
  //     cacheNames.forEach(cacheName => {
  //       caches.delete(cacheName).then(() => {
  //         console.log(`Deleted cache: ${cacheName}`);
  //       });
  //     });
  //   });
  // }

  // Register the new service worker
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(registration => {
      console.log("Service Worker registered with scope:", registration.scope);

      // Listen for messages from the service worker
      navigator.serviceWorker.addEventListener("message", event => {
        console.log("Message received from service worker:", event.data);

        const payload = event.data.data;
        // Dispatch actions based on the payload
        if (payload.data && payload.data.title === "New message") {
          store.dispatch("chat/addMessageFromFcm", payload.data);
        }
        if (payload.data && payload.data.title === "Message read") {
          store.dispatch("chat/updateChatwithMessageReadStatus", payload.data);
        }
      });
    })
    .catch(error => {
      console.log("Service Worker registration failed:", error);
    });
}

new Vue({
  i18n,
  router,
  store,
  vuetify,
  firebaseApp, // Initialize Firebase
  render: h => h(App),
  created() {
    // Monitor background messages
    navigator.serviceWorker.addEventListener("message", event => {
      console.log(
        "Received a message from service worker: ",
        event.data.data.title
      );
      // store dispatch addmessagefromfcm
      if (event.data.data && event.data.data.title === "New message") {
        console.log("working inside new message");
        this.$store.dispatch("chat/addMessageFromFcm", event.data.data);
      }
      if (event.data.data.title === "Message read") {
        console.log("message read notification received");
        this.$store.dispatch(
          "chat/updateChatwithMessageReadStatus",
          event.data.data
        );
      }
    });

    // Handle when the app is opened
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        console.log("App is visible");
      } else {
        console.log("App is not visible");
      }
    });
  }
}).$mount("#app");
