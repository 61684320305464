<template>
  <v-app class="app-bg">
    <navigation v-if="showMenu" />
    <v-main
      class="content-view"
      :style="
        showMenu && $vuetify.breakpoint.xs && $route.name == 'need-map'
          ? 'padding-top:56px;'
          : showMenu &&
            $vuetify.breakpoint.xs &&
            $route.name != 'mobile-chat-room'
          ? 'padding-top:70px; padding-bottom: 56px'
          : showMenu
          ? 'padding-top:56px;'
          : ''
      "
    >
      <router-view />
    </v-main>
    <notifications group="message" position="bottom left" />
    <bottom-navigation
      v-if="showMenu && $route.name != 'mobile-chat-room'"
      class="hidden-sm-and-up"
    />
    <!-- <a-i-chat-support /> -->
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import BottomNavigation from "./components/BottomNavigation.vue";
// import AIChatSupport from "./components/common/AIChatSupport.vue";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Default Title",
    // all titles will be injected into this template
    titleTemplate: "%s | My Awesome Webapp"
  },
  components: {
    Navigation,
    BottomNavigation,
    // AIChatSupport
  },
  data() {
    return {
      showMenu: false
    };
  },
  watch: {
    $route(to) {
      if (
        to.name === "login" ||
        to.name === "landing" ||
        to.name == "agency-register" ||
        to.name == "business-register" ||
        to.name == "user-register" ||
        to.name == "forgot-password" ||
        to.name == "verification-code" ||
        to.name == "reset-password" ||
        to.name == "facebook-callback" ||
        to.name == "instagram-callback" ||
        to.name == "linkedin-callback" ||
        to.name == "twitter-callback" ||
        to.name == "tiktok-callback"
      ) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    }
  },
  mounted() {
    if (
      this.$route.name === "login" ||
      this.$route.name === "landing" ||
      this.$route.name == "agency-register" ||
      this.$route.name == "business-register" ||
      this.$route.name == "user-register" ||
      this.$route.name == "forgot-password" ||
      this.$route.name == "verification-code" ||
      this.$route.name == "reset-password" ||
      this.$route.name == "facebook-callback" ||
      this.$route.name == "instagram-callback" ||
      this.$route.name == "linkedin-callback" ||
      this.$route.name == "twitter-callback" ||
      this.$route.name == "tiktok-callback" ||
      this.$route.name == null
    ) {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }
  }
};
</script>
<style lang="scss">
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}
html {
  height: 100%;
}
html #detach-button-host {
  z-index: 10000 !important;
}
.app-bg {
  background: #3b7ca1 !important;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.content-view {
  min-height: calc(100vh - 56px); //70px ???
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.v-main__wrap {
  min-height: inherit;
}
.url-button {
  cursor: pointer;
}
.url-button:hover {
  text-decoration: underline;
  color: #0a66c2;
}
.cursor-pointer {
  cursor: pointer;
}

///Text
.text-block {
  white-space: pre-line;
  overflow: hidden;
  display: block;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.break-word {
  word-break: break-word;
}
.text-transform-none {
  text-transform: none;
}
.line-height-15 {
  line-height: 15px !important;
}
.text-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

///Colors
.primary-color {
  color: #0a5b8a !important;
}
.default-font-color {
  color: #031723;
}
.dark-font-color {
  color: #1e1e1e !important;
}
.dark-gray-color {
  color: #242f36;
}
.dark-blue-color {
  color: #052e45;
}
.white-font-color {
  color: white;
}
.dark-white-color {
  color: #9dbdd0;
}
.blue-font-color {
  color: #5b2cae;
}
.gray-font-color {
  color: #919191;
}
.gray-400 {
  color: #5c5b62;
}
.gray-500 {
  color: #24a39a;
}
.disabled-font-color {
  color: lightgray;
}
.selected-item-color {
  background-color: #55eef3f8;
}
.hover-item-color {
  color: #55eef3f8;
}
.bg-white {
  background-color: white;
}
.bg-primary {
  background-color: #0a5b8a;
}

.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.h-100 {
  height: 100% !important;
}
.h-50 {
  height: 50% !important;
}
.min-width-200 {
  min-width: 200px;
}
.max-width-650 {
  max-width: 650px;
}
.max-width-1500 {
  max-width: 1500px !important;
}
.max-width-2000 {
  max-width: 2000px;
}
.force-height-30 {
  max-height: 30px !important;
  min-height: 30px !important;
}
.force-width-400 {
  max-width: 400px !important;
  min-width: 400px !important;
}
.max-height-0 {
  max-height: 0;
}
.min-height-inherit {
  min-height: inherit;
}
.top-70 {
  top: 70px;
}
.b-0 {
  bottom: 0;
}
.r-0 {
  right: 0;
}

//Font

.font-8 {
  font-size: 8px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-28 {
  font-size: 28px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-40 {
  font-size: 40px !important;
}
.font-50 {
  font-size: 50px !important;
}
.font-64 {
  font-size: 64px !important;
}
.font-bold {
  font-weight: bold;
}
.app-bold-font {
  font-family: "Poppins-SemiBold" !important;
}
.app-medium-font {
  font-family: "Poppins-Medium" !important;
}
.app-regular-font {
  font-family: "Poppins-Regular" !important;
}
.font-normal {
  font-weight: normal;
}

//Layout
.position-sticky {
  position: sticky;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.justify-between {
  justify-content: space-between !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 0;
}
.hover-item:hover {
  background-color: #eef3f8;
  cursor: pointer;
}
.hover-scroll:hover {
  overflow-y: auto !important;
}
.hover-scroll::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-y-scroll {
  overflow-y: scroll;
}

/* Track */
.hover-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.hover-scroll::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.hover-scroll::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

#gl-widget-modal {
  display: none;
}

@media (max-width: 600px) {
  .vue-notification-group {
    bottom: 56px !important;
  }
}
</style>
