<template>
  <v-app-bar
    color="#084468"
    max-height="56"
    height="56"
    elevation="0"
    fixed
    style="z-index:10000;"
  >
    <div style="flex-grow: 1; display: flex;" class="mt-2 mb-2">
      <div style="height: 40px" class="logo">
        <v-img
          src="../assets/logo.svg"
          @click="onBack"
          height="40"
          contain
          :class="{
            'mt-sm-1 mt-md-1 mt-lg-1 mt-xl-1 logo-mobile':
              profile._id || loading
          }"
        />
      </div>
      <div v-if="isAuthenticated && profile.type !== 'Company'" class="ml-14">
        <v-menu offset-y v-model="searchDropdown">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="searchKey"
              hide-details
              placeholder="Search"
              solo
              flat
              dense
              class="ml-2 mt-sm-1 mt-md-1 mt-lg-1 mt-xl-1 search-bar"
              elevation="0"
              v-bind="attrs"
              v-on="on"
              background-color="#D6CDF7"
              autocomplete="off"
              @keyup.enter="onSearch"
              @input="onChangeSearch"
            >
              <template v-slot:append>
              <v-icon class="mr-2" @click="onSearch">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </template>
          <div style="max-height: 60vh" v-if="profile.type !== 'Company'">
            <v-list>
               <v-list-item @click="onSearch" class="list-item">
                <v-list-item-title>
                  <!-- <v-icon class="mr-2">mdi-magnify</v-icon>Search
                  {{ searchKey ? `for "${searchKey}"` : "" }} -->
                    <v-icon class="mr-2">mdi-magnify</v-icon>Search
                    {{ searchKey && searchKey.length > 60 ? `for "${searchKey.substring(0, 60)}..."` : searchKey ? `for "${searchKey}"` : "" }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="(item, index) in recentSearches"
                :key="index"
                class="list-item"
              >
                <v-list-item-title
                  @click="onSelectKey(item.key)"
                  style="display: flex; justify-content: space-between; align-items: center"
                >
                  {{ item.key.length > 60 ? item.key.substring(0, 60) + '...' : item.key }}
                  <v-btn icon @click.stop="onDeleteKey(item)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="onSearch" class="list-item">
                <v-list-item-title>
                  <v-icon class="mr-2">mdi-magnify</v-icon>Search
                  {{ searchKey ? `for "${searchKey}"` : "" }}
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </div>
        </v-menu>
      </div>
      <v-tabs
        optional
        right
        color="#B5E539"
        background-color="transparent"
        class="px-5 hidden-xs-only webViewMenu"
        @change="onSelected"
        v-model="selectedMenu"
        v-if="profile._id && profile.type !== 'Company'"
      >
        <v-tab v-for="item in menus" :key="item.title">
          <navigation-menu-icon
            :icon="item.title"
            :selected="checkSelected(item)"
          />
        </v-tab>
      </v-tabs>
      <div v-else-if="!loading && profile.type !== 'Company'" class="d-flex flex-row justify-end w-100">
        <v-btn class="me-5 rounded-lg" outlined color="white" @click="onLogin"
          >Login</v-btn
        >
        <v-btn class="me-5 rounded-lg" @click="onRegister">Get started</v-btn>
      </div>
    </div>
    <div class="hidden-sm-and-down profile-mobile" v-if="isAuthenticated && profile.type !== 'Company'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            class="elevation-6 ml-5"
            size="40"
            v-bind="attrs"
            v-on="on"
            @click="goToProfile"
          >
            <v-img
              :src="
                profile.thumbnail
                  ? profile.thumbnail
                  : profile.image
                  ? profile.image
                  : placeholderImage
              "
            />
          </v-avatar>
        </template>
        <span>{{
          profile.name
            ? profile.name
            : profile.first_name + " " + profile.last_name
        }}</span>
      </v-tooltip>
    </div>
    <div class="text-center mr-md-6 mr-lg-6 mr-xl-6" v-if="isAuthenticated && profile.type !== 'Company'">
      <v-menu offset-y rounded="lg">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="d-flex flex-row">
            <v-btn icon>
              <v-icon color="white">
                mdi-menu-down
              </v-icon>
            </v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in dropmenus"
            v-on:change="onSelectedMenu(index)"
            :key="index"
            link
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
        <div class="hidden-sm-and-down profile-mobile" v-if="isAuthenticated && profile.type === 'Company'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            class="elevation-6 ml-5"
            size="40"
            v-bind="attrs"
            v-on="on"
            @click="goToProfile"
          >
            <v-img
              :src="
                profile.thumbnail
                  ? profile.thumbnail
                  : profile.image
                  ? profile.image
                  : placeholderImage
              "
            />
          </v-avatar>
        </template>
        <span>{{
          profile.name
            ? profile.name
            : profile.first_name + " " + profile.last_name
        }}</span>
      </v-tooltip>
    </div>
    <div class="text-center mr-md-6 mr-lg-6 mr-xl-6" v-if="isAuthenticated && profile.type === 'Company'">
      <v-menu offset-y rounded="lg">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="d-flex flex-row">
            <v-btn icon>
              <v-icon color="white">
                mdi-menu-down
              </v-icon>
            </v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in companydropmenus"
            v-on:change="onSelectedMenu(index)"
            :key="index"
            link
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <v-app-bar-nav-icon
      @click="showMenu()"
      class="mr-4 mobileViewHam"
    ></v-app-bar-nav-icon>
    <v-tabs
      optional
      right
      color="#B5E539"
      background-color="transparent"
      class="px-5 hidden-xs-only nav-content  mobilenav"
      :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"
      @change="onSelected"
      v-model="selectedMenu"
      v-if="profile._id"
    >
      <v-tab v-for="item in menus" :key="item.title">
        <navigation-menu-icon
          :icon="item.title"
          :selected="checkSelected(item)"
        />
      </v-tab>
    </v-tabs>
  </v-app-bar>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import NavigationMenuIcon from "./common/NavigationMenuIcon.vue";
export default {
  components: { NavigationMenuIcon },
  data() {
    return {
      showMobileMenu: false,
      dropmenus: [
        { title: "Profile" },
        { title: "Settings" },
        { title: "Logout" }
      ],
      companydropmenus : [
        { title: "Profile" },
        { title: "Logout" }
      ],
      menus: [
        {
          title: "home",
          router: "home",
          path: "/"
        },
        {
          title: "needs",
          router: "agency-need-feeds",
          path: "/needs/feeds"
        },
        // {
        //   title: "shops",
        //   router: "agency-shop",
        //   path: "/agency-shop",
        //   alterPath: "/volunteer-shop",
        // },
        {
          title: "chat",
          router: "chat",
          path: "/chat"
        },
        {
          title: "notifications",
          router: "notifications",
          path: "/notifications"
        }
      ],
      placeholderImage: require("../assets/default.jpg"),
      selectedMenu: -1,
      searchKey: "",
      recentSearches: null,
      searchDropdown: false,
      snackbar: false,
      errorMessage: null,
      loading: false
    };
  },
  methods: {
    ...mapActions({
      onLogout: "auth/onLogout",
      fetchProfile: "auth/fetchProfile",
      initConversationsClient: "chat/initConversationsClient",
      getSearchHistory: "search/getSearchHistory",
      getSearchContents: "search/getSearchContents",
      deleteKey: "search/deleteKey"
    }),
    onSelected(index) {
      if (index != undefined) {
        if (this.$route.name && this.$route.name != this.menus[index].router) {
          this.$router.push({ name: this.menus[index].router });
        }
      } else {
        setTimeout(() => {
          for (var i = 0; i < this.menus.length; i++) {
            if (
              this.menus[i].router == this.$route.name ||
              this.menus[i].path == this.$route.matched[0].path
            ) {
              this.selectedMenu = i;
              break;
            }
          }
        }, 300);
      }
    },
    onSelectedMenu(index) {
      if (this.dropmenus[index].title == "Profile" ) {
        this.selectedMenu = -1;
        this.goToProfile();
      } else if (this.dropmenus[index].title == "Logout") {
        this.onLogout().then(() => {
          this.$router.push({ name: "landing" });
        });
      } else if (this.dropmenus[index].title == "Settings") {
        this.selectedMenu = -1;
        this.$router.push({ name: "settings" });
      }
       if (this.profileType == "Company") {
      if (this.companydropmenus[index].title == "Profile" ) {
        this.selectedMenu = -1;
        this.goToProfile();
      } else if (this.companydropmenus[index].title == "Logout") {
        this.onLogout().then(() => {
          this.$router.push({ name: "landing" });
        });
      }
       }

    },
    onBack() {
      this.$router.push({ name: "home" });
    },
    checkSelected(item) {
      return item === this.menus[this.selectedMenu];
    },
    goToProfile() {
      if (this.profileType == "Agency") {
        if (
          "agency-profile" !== this.$route.name &&
          "/agency" !== this.$route.matched[0].path
        ) {
          this.$router.push({ name: "agency-profile" });
        }
      } else if (this.profileType == "Volunteer") {
        if (
          "volunteer-profile" !== this.$route.name &&
          "/volunteer" !== this.$route.matched[0].path
        ) {
          this.$router.push({ name: "volunteer-profile" });
        }
      } else if (this.profileType == "Company") {
        if (
          "company-profile" !== this.$route.name &&
          "/company" !== this.$route.matched[0].path
        ) {
          this.$router.push({ name: "company-profile" });
        }
      } else if (this.profileType == "Admin") {
        this.$router.push({ name: "admin-profile" });
      }
    },
    getSelected() {
      this.selectedMenu = -1;
      if (
        this.$route.name == "volunteer-home" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/volunteer/home") ||
        this.$route.name == "agency-home" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/agency/home") ||
        this.$route.name == "admin-home" ||
        this.$route.name == "company-home" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/company/home")
      ) {
        this.selectedMenu = 0;
        return;
      } else if (
        this.$route.name == "volunteer-need-feeds" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/volunteer/needs") ||
        this.$route.name == "agency-need-feeds" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/agency/needs") ||
        this.$route.name == "company-need-feeds" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/company/needs")
      ) {
        this.selectedMenu = 1;
        return;
      }
      for (var i = 0; i < this.menus.length; i++) {
        if (
          this.menus[i].router == this.$route.name ||
          (this.$route.matched[0] &&
            this.menus[i].path == this.$route.matched[0].path)
        ) {
          this.selectedMenu = i;
          break;
        }
      }
    },
    onSearch() {
      if (this.searchKey.trim() === "") {
      return;
      }
      if (this.$route.name == "search") {
      if (this.$route.query.q != this.searchKey) {
        this.$router.replace({
        query: {
          q: this.searchKey,
          category: "All"
        }
        });
        this.searchDropdown = false;
      }
      } else {
      this.$router.push({ name: "search", query: { q: this.searchKey } });
      this.searchDropdown = false;
      }
    },
    onChangeSearch(value) {
      if (value !== '' || value !== null) {
        this.searchDropdown = true;
      }
    },
    onSelectKey(key) {
      this.searchKey = key;
      this.searchDropdown = false;
      this.onSearch();
    },
    onDeleteKey(key) {
      this.deleteKey({ _id: key._id })
        .then(res => {
          this.recentSearches = this.recentSearches.filter(
            r => r._id != res._id
          );
        })
        .catch(error => {
          console.log(error.response.data.message);
        });
    },
    onLogin() {
      this.$router.push({ name: "login" });
    },
    onRegister() {
      this.$router.push({ name: "user-register" });
    },
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      language: "usersetting/getLanguage"
    }),
    ...mapState("auth", {
      profile: "profile",
      profileType: "type"
    })
  },
  watch: {
    "$route.name": function(newValue) {
      this.getSelected();
      this.searchKey = "";
      if (newValue == "search") {
        this.searchKey = this.$route.query.q;
      }
    },
    searchDropdown(newValue) {
      if (newValue) {
        this.getSearchHistory({ type: "Total" })
          .then(res => {
            this.recentSearches = res;
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  created() {
    this.getSelected();
    this.loading = true;
    this.fetchProfile()
      .then(() => {
        this.loading = false;
        //this.initConversationsClient();
        this.$i18n.locale = this.language;
      })
      .catch(error => {
        this.loading = false;
        if (
          error == "User not found" ||
          error == "Permission denied" ||
          error == "Agency not found" ||
          error == "Admin not found"
        ) {
          this.snackbar = true;
          this.errorMessage =
            "Your account has been deleted or something went wrong. Please login again!";
          this.onLogout().then(() => {
            this.$router.push({ name: "landing" });
          });
        } else if (
          error == "Failed to authenticate token." ||
          error == "Invalid token."
        ) {
          this.snackbar = true;
          this.errorMessage = "Your token has some problem, please login again";
          this.onLogout().then(() => {
            this.$router.push({ name: "landing" });
          });
        }
      });
    if (this.$route.name == "search") {
      this.searchKey = this.$route.query.q;
    }
  }
};
</script>
<style scoped>
.list-item:hover {
  background: #eef3f8;
}

.search-bar {
  border-radius: 20px;
  width: 25vw;
}
</style>
