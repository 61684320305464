import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    competitions: [],
    competition:null,
    agencyFollowers: [],
    respectiveCompetition : []
  },
  getters: {
    getAllCompetitions(state) {
      return state.competitions;
    },
    getAgencyCompetitions(state) {
      return state.competitions;
    },
    getAgencyFollowers(state) {
      return state.agencyFollowers;
    },
    competitionTeam(state) {
      return state.competition;
    },
    competitionTeamParticipated(state) {
      return state.competition;
    },
    respondCompetition(state) {
      return state.competition;
    }
  },
  mutations: {
    setCompetitions(state, data) {
      state.competitions = data;
    },
    eachCompetitions(state, data) {
      state.competitions = data;
    },
    respectiveCompetitions(state, data) {
      state.respectiveCompetition = data;
    },
    setAgencyFollowers(state, data) {
      state.agencyFollowers = data;
    },
    addCompetition(state, data) {
      const item = state.competitions.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.competitions.unshift(data);
      }
    },
    editCompetition(state, data) {
      const item = state.competitions.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      }
    },
    setCompetition(state, competition) {
      state.competition = competition;
    },
    REMOVE_COMPETITION(state, competitionId) {
      state.competitions = state.competitions.filter(
        competition => competition._id !== competitionId._id
      );
    },
    respondCompetition(state, competitionTeam) {
      state.competition = competitionTeam;
    }
  },
  actions: {
    fetchAllCompetitions({ commit, rootState }) {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .get("competition/getAllCompetitions")
            .then(res => {
              if (res.data.competitions) {
                commit("setCompetitions", res.data.competitions);
                resolve(res.data.competitions);
              } else {
                reject(new Error(res.data.message)); 
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      fetchEachCompetitions({ commit, rootState }) {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
        .get("competition/getAgencyCompetitions")
        .then((res) => {
          if (res.data.competitions) {
            commit("eachCompetitions", res.data.competitions);
            resolve(res.data.competitions);
          } else {
            reject(new Error(res.data.message));
          }
        })
        .catch((error) => {
          reject(
            new Error(
          error.response?.data?.message ||
            `Failed to fetch agency competitions: ${error.message}`
            )
          );
        });
        });
      },
      respectiveCompetition({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("competitionTeam/getCompetitionTeamsByCompetitionId", params, {
              Authorization: rootState.auth.token
              })
            .then(res => {
              if (res.data.competition_detailedView) {
                commit("respectiveCompetitions", res.data.competition_detailedView);
                resolve(res.data.competition_detailedView);
              } else {
                reject(new Error(res.data.message)); 
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      getCompetition({ commit, rootState }) {
        return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .get("competition/getAgencyFollowers", {
          Authorization: rootState.auth.token
          })
          .then(res => {
          if (res.data.success == true || res.data.followers) {
            commit("setAgencyFollowers", res.data.followers);
            resolve(res.data.followers);
          } else {
            reject(res.data.message);
          }
          })
          .catch(error => {
          reject(error);
          });
        });
      },
    addCompetition({ commit, rootState, dispatch }, params) {
      return new Promise((resolve, reject) => {
      axiosInstance(rootState.auth.token)
        .post("competition/createCompetition", params, {
        Authorization: rootState.auth.token
        })
        .then(res => {
          
        if (res.data.competition) {
          commit("addCompetition", res.data.competition);
          dispatch("fetchEachCompetitions").then(() => {
            resolve(res.data.competition);
          }); // Fetch all competitions to refresh the data
        } else {
          reject(res.data.message);
        }
        })
        .catch(error => {
        reject(error);
        });
      });
    },
    editCompetition({ commit, rootState, dispatch }, params) {
      return new Promise((resolve, reject) => {
      axiosInstance(rootState.auth.token)
        .post("competition/updateCompetition", params, {
        Authorization: rootState.auth.token
        })
        .then(res => {
          console.log(res);
        if (res.data.competition) {
          commit("editCompetition", res.data.competition);
          dispatch("fetchEachCompetitions");
          resolve(res.data.competition);
        } else {
          reject(new Error(res.data.message));
        }
        })
        .catch(error => {
        reject(error);
        });
      });
    },
    detailedCompetition({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("competitionTeam/getCompetitionTeamsByCompetitionId", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.competition_detailedView) {
              commit("setCompetition", res.data.competition_detailedView);
              resolve(res.data.competition_detailedView);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteCompetition({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
      axiosInstance(rootState.auth.token)
        .post("competition/deleteCompetition", params, {
        Authorization: rootState.auth.token
        })
        .then(res => {
        if (res.data.success === true) {
          commit("REMOVE_COMPETITION", params.competitionId);
          resolve(res.data.message);
        } else {
          reject(res.data.message);
        }
        })
        .catch(error => {
        reject(error);
        });
      });
    },
    getCompetitionTeamInvitation({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .get("competitionTeam/getCompetitionTeamInvitation", {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.status && res.data.data) {
              commit("competitionTeam", res.data.data);
              resolve(res.data.data);
            } else {
              reject(new Error(res.data.message || "No competition teams found for the given agency ID."));
            }
          })
          .catch(error => {
            reject(new Error(error.response?.data?.message || `Failed to fetch competition team invitation: ${error.message}`));
          });
        });
    },
    getParticipatedCompetitionTeam({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .get("competitionTeam/getParticipatedCompetitionTeam", {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.data) {
              commit("competitionTeamParticipated", res.data.data);
              resolve(res.data.data);
            } else {
              reject(new Error(res.data.message || "No competition teams found for the given agency ID."));
            }
          })
          .catch(error => {
            reject(new Error(error.response?.data?.message || `Failed to fetch competition team invitation: ${error.message}`));
          });
        });
    },
    respondToCompetitionRequest({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("competitionTeam/respondToCompetitionRequest", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.status === true) {
              commit("respondCompetition", res.data.competitionTeam);
              resolve(res.data.competitionTeam);
              if (!res.data.status) {
                reject(res.data.message);
              }
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
}
};
