<template>
  <v-card
    elevation="8"
    class="card_home rounded-xl h-100"
    @mouseover="active = true"
    @mouseleave="active = false"
    @click="goToBusiness"
    color="white"
  >
    <v-card-title>
      Business
    </v-card-title>
    <v-card-text>
      In this section you can check the exact number of registered Business.
    </v-card-text>
    <v-card-text class="d-flex mb-3">
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">{{getCompanyCount.registeredCompanies}}</div>
        <div class="gray-400 mt-1">Registered</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">
          {{getCompanyCount.pendingCompanies}}
        </div>
        <div class="gray-400 mt-1 text-center">Waiting approved</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="gray-500 font-24 app-bold-font">{{getCompanyCount.availableCompanies}}</div>
        <div class="gray-400 mt-1">Approved</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="error--text font-24 app-bold-font">{{getCompanyCount.rejectedCompanies}}</div>
        <div class="gray-400 mt-1">Rejected</div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      active: false
    };
  },
  computed: {
    ...mapGetters("adminDashboard", ["getCompanyCount"])
  },
  methods: {
    ...mapActions("adminDashboard", ["fetchCompanyCountDetails"]),
    goToBusiness() {
      this.$router.push({ name: "admin-company" });
    }
  },
      mounted() {
        this.fetchCompanyCountDetails();
      },
};
</script>
<style scoped>
.card_home {
  min-height: 100px;
  background-image: linear-gradient(#efecf4, #f6f4f9, #fbfafc);
  bottom: 0;
  width: 100%;
  /* height: 100%; */
  margin: 0px;
  padding: 0px;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.item_count {
  color: white;
  background-color: #ea1818;
  font-family: "Poppins-Medium";
  font-size: 20px;
}
</style>
