import axiosInstance from "@/api/axios";

export default {
    namespaced: true,
    state: {
        agencyCounts: [],
        companyCounts: [],
        adsCounts: [],
        needsCounts: [],
        eventCounts: [],
        agencyGraph: [],
        needGraph:[],
        eventGraph: [],
        volunteersGraph: []
    },
    getters: {
        getAgencyCount(state) {
            return state.agencyCounts;
        },
        getCompanyCount(state) {
            return state.companyCounts;
        },
        getAdsCount(state) {
            return state.adsCounts;
        },
        getNeedsCount(state) {
            return state.needsCounts;
        },
        getEventCount(state) {
            return state.eventCounts
        },
        agencyGraphData(state) {
            return state.agencyGraph;
        },
        needGraphData(state) {
            return state.needGraph;
        },
        eventGraphData(state) {
            return state.eventGraph;
        },
        volunteersGraphData(state) {
            return state.volunteersGraph;
        }
    },
    mutations: {
        setAgencyCount(state, data) {
            state.agencyCounts = data;
        },
        setCompanyCount(state, data) {
            state.companyCounts = data;
        },
        setAdsCount(state, data) {
            state.adsCounts = data;
        },
        setNeedsCount(state, data) {
            state.needsCounts = data;
        },
        setEventCount(state, data) {
            state.eventCounts = data;
        },
        agencyGraph(state, data) {
            state.agencyGraph = data;
        },
        needGraph(state, data) {
            state.needGraph = data;
        },
        eventGraph(state, data) {
            state.eventGraph = data;
        },
        volunteersGraph(state, data) {
            state.volunteersGraph = data;
        }
    },
    actions: {
        fetchAgencyCountDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                axiosInstance(rootState.auth.token)
                    .post("admin/getAgencyDetails")
                    .then(res => {
                        if (res.data.success) {
                            commit("setAgencyCount", res.data.res);
                            resolve(res.data.res);
                        } else {
                            reject(new Error(res.data.message));
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchCompanyCountDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                axiosInstance(rootState.auth.token)
                    .post("admin/getCompanyDetails")
                    .then(res => {
                        if (res.data.success) {
                            commit("setCompanyCount", res.data.res);
                            resolve(res.data.res);
                        } else {
                            reject(new Error(res.data.message));
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchAdsCountDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                axiosInstance(rootState.auth.token)
                    .post("admin/getAdsStatistics")
                    .then(res => {
                        if (res.data.success) {
                            commit("setAdsCount", res.data.res);
                            resolve(res.data.res);
                        } else {
                            reject(new Error(res.data.message));
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchNeedCountDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                axiosInstance(rootState.auth.token)
                    .post("admin/getNeedsStatistics")
                    .then(res => {
                        if (res.data.success) {
                            commit("setNeedsCount", res.data.res);
                            resolve(res.data.res);
                        } else {
                            reject(new Error(res.data.message));
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchEventCountDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                axiosInstance(rootState.auth.token)
                    .post("admin/getEventStatistics")
                    .then(res => {
                        if (res.data.success) {
                            commit("setEventCount", res.data.res);
                            resolve(res.data.res);
                        } else {
                            reject(new Error(res.data.message));
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchAgencyGraphDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                axiosInstance(rootState.auth.token)
                    .post("admin/getAgencyGraph")
                    .then(res => {
                        if (res.data.success) {
                            commit("agencyGraph", res.data.data);
                            resolve(res.data.data);
                        } else {
                            reject(new Error(res.data.message));
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchNeedGraphDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                axiosInstance(rootState.auth.token)
                    .post("admin/getNeedsGraph")
                    .then(res => {
                        if (res.data.success) {
                            commit("needGraph", res.data.data);
                            resolve(res.data.data);
                        } else {
                            reject(new Error(res.data.message));
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchEventGraphDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                axiosInstance(rootState.auth.token)
                    .post("admin/getEventsGraph")
                    .then(res => {
                        if (res.data.success) {
                            commit("eventGraph", res.data.data);
                            resolve(res.data.data);
                        } else {
                            reject(new Error(res.data.message));
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchVolunteersGraphDetails({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                axiosInstance(rootState.auth.token)
                    .post("admin/getVolunteersGraph")
                    .then(res => {
                        if (res.data.success) {
                            commit("volunteersGraph", res.data.data);
                            resolve(res.data.data);
                        } else {
                            reject(new Error(res.data.message));
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
}
