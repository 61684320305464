import axiosInstance from "@/api/axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    companies: []
  },
  getters: {
    getAllCompanies(state) {
      return state.companies;
    }
  },
  mutations: {
    SET_COMPANIES(state, data) {
      state.companies = Array.isArray(data) ? data : [];
    },
    updateCompanyStatus(state, { companyId, status }) {
      const companyIndex = state.companies.findIndex(c => c._id === companyId);
      if (companyIndex !== -1) {
        state.companies[companyIndex].status = status;
      }
    },
    UPDATE_COMPANY(state, updatedCompany) {
      const index = state.companies.findIndex(c => c._id === updatedCompany._id);
      if (index !== -1) {
        Vue.set(state.companies, index, { ...updatedCompany }); // ✅ Ensures Vuex reactivity
      }
    },
    REMOVE_COMPANY(state, companyId) {
      state.companies = state.companies.filter(company => company._id !== companyId);
    }
  },
  actions: {
    fetchAllCompanies({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("company/companyAll")
          .then(res => {
            if (res.data.success == true) {
              commit("SET_COMPANIES", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    approveCompany({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("company/approveCompany", payload)
          .then(res => {
            if (res.data.success) {
              commit("updateCompanyStatus", { companyId: payload.companyId, status: payload.status });
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },    
    updateCompany({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("company/updateCompany", payload)
          .then(res => {
            if (res.data.success == true) {
              commit("UPDATE_COMPANY", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteCompany({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("company/deleteCompany", payload)
          .then(res => {
            console.log("Delete API Response:", res.data); // Debugging
    
            if (res.data.success) {
              commit("REMOVE_COMPANY", payload._id);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            console.error("Delete Error:", error);
            reject(error);
          });
      });
    }    
  }
};
