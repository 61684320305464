import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import agency from "./modules/agency";
import event from "./modules/event";
import need from "./modules/need";
import response from "./modules/response";
import volunteer from "./modules/volunteer";
import timeslot from "./modules/timeslot";
import blog from "./modules/blog";
import notification from "./modules/notification";
import weather from "./modules/weather";
import chat from "./modules/chat";
import search from "./modules/search";
import awards from "./modules/awards";
import business from "./modules/business";
import ads from "./modules/ads";
import usersetting from "./modules/usersetting";
import icon from "./modules/siteicons";
import messagelog from "./modules/messagelog";
import donation from "./modules/donation";
import competition from "./modules/competition";
import members from "./modules/members";
import colour from "./modules/colour";
import company from "./modules/company";
import adminDashboard from "./modules/adminDashboard";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    agency,
    event,
    need,
    volunteer,
    response,
    timeslot,
    blog,
    notification,
    weather,
    chat,
    search,
    awards,
    business,
    ads,
    usersetting,
    icon,
    messagelog,
    donation,
    competition,
    members,
    colour,
    company,
    adminDashboard
  }
});
