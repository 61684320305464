// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging"; // Import FCM functions
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYALiK_IIcXp-EJVS1StgKDAeVsdiDbU4",
  authDomain: "akindrvolunteer.firebaseapp.com",
  projectId: "akindrvolunteer",
  storageBucket: "akindrvolunteer.firebasestorage.app",
  messagingSenderId: "644902765089",
  appId: "1:644902765089:web:ae418c80ce6115d80ad960",
  measurementId: "G-MV6BC00CNY"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(firebaseApp);

// console log if firebase is initialized
console.log("Firebase initialized");

export { firebaseApp, messaging, getToken }; // Export messaging and getToken
