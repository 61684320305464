<template>
  <v-card
    elevation="8"
    class="card_home rounded-xl h-100"
    @mouseover="active = true"
    @mouseleave="active = false"
    @click="goToAds"
    color="white"
  >
    <v-card-title>
      Ads
    </v-card-title>
    <v-card-text>
      In this section you can check the exact number of registered Ads.
    </v-card-text>
    <v-card-text class="d-flex mb-3">
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">{{getAdsCount.totalVideos}}</div>
        <div class="gray-400 mt-1">Videos</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="primary-color font-24 app-bold-font">{{getAdsCount.totalImages}}</div>
        <div class="gray-400 mt-1">Images</div>
      </div>
      <div class="flex-grow d-flex flex-column align-center">
        <div class="gray-500 font-24 app-bold-font">{{getAdsCount.totalAds}}</div>
        <div class="gray-400 mt-1">Total</div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      active: false
    };
  },
    computed: {
    ...mapGetters("adminDashboard", ["getAdsCount"])
  },
  methods: {
    ...mapActions("adminDashboard", ["fetchAdsCountDetails"]),
    goToAds() {
      this.$router.push({ name: "admin-ads" });
    }
  },
  mounted() {
    this.fetchAdsCountDetails();
  }
};
</script>
<style scoped>
.card_home {
  min-height: 100px;
  background-image: linear-gradient(#efecf4, #f6f4f9, #fbfafc);
  bottom: 0;
  width: 100%;
  /* height: 100%; */
  margin: 0px;
  padding: 0px;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.item_count {
  color: white;
  background-color: #ea1818;
  font-family: "Poppins-Medium";
  font-size: 20px;
}
</style>
